import React from "react";
import DemoLabel from "../../components/demoLabel/demoLabel";
import DataLayer from "../../analytics/dataLayer";
import Measures from "../../components/measures/measures";
import Navigation from "../../components/navigation/navigation";
import ProductDetails from "../../components/productDetails/productDetails";
import Services from "../../components/services/services";
import Specifications from "../../components/specifications/specifications";
import { isPlutonRmc } from "../../utils/helpers/functions.helper";

function Product({
  watchData,
  businessData,
  loading,
  rightToLeft,
  lightTheme,
  displayMetas,
  setScrollLock,
  scrollLock,
}) {
  const measuresData = watchData?.Measures;

  const typesArr = [
    "TDR_CASE_TITLE",
    "TDR_CASE_WATER_RESISTANCE",
    "TDR_CASE_BEZEL",
    "TDR_DIAL",
    "TDR_CASE_CRYSTAL",
    "TDR_MOVEMENT_CALIBRE",
    "TDR_MOVEMENT_AUTONOMY",
    "TDR_CASE_WINDING_CROWN",
    "TDR_BRACELET_TITLE",
  ];
  let specificationFlag = false;

  try {
    const translationsArray = businessData[0]?.Translations;
    let translationsFilterArray = [];

    if (translationsArray && translationsArray.length) {
      typesArr.map((item) => {
        translationsArray.filter((translationsObj) => {
          if (translationsObj.Type === item && translationsObj.Translation) {

            if (!translationsFilterArray.includes(item)) {
              translationsFilterArray.push(translationsObj.Type);
            }

            if (typesArr.length === translationsFilterArray.length) {
              specificationFlag = true;
            }
          }
        });
      });
    }
  } catch (e) {
    console.log("Catch block", e);
  }

  return (
    <>
      <DataLayer
        businessData={businessData}
        watchData={watchData}
        status="200"
        loading={loading}
      />
      <DemoLabel />
      <Navigation
        setScrollLock={setScrollLock}
        displayYourWatch
        lightTheme={lightTheme}
        rightToLeft={rightToLeft}
        scrollLock={scrollLock}
      />
      <ProductDetails
        watchData={watchData}
        businessData={businessData}
        lightTheme={lightTheme}
        displayMetas={displayMetas}
      />
      {watchData?.Services?.length ? (
        <Services
          services={watchData?.Services}
          rightToLeft={rightToLeft}
          warrantyEndDate={watchData?.WarrantyEndDate}
          lightTheme={lightTheme}
        />
      ) : null}
      {displayMetas && (
        <Measures measuresData={measuresData} rightToLeft={rightToLeft} isPluton={isPlutonRmc(watchData?.Configuration?.Rmc)} />
      )}
      {watchData?.Configuration?.Rmc && specificationFlag ? (
        <Specifications
          businessData={businessData}
          rightToLeft={rightToLeft}
          lightTheme={lightTheme}
        />
      ) : null}
      <DataLayer pageBottom />
    </>
  );
}

export default Product;

import React from "react";
import { withTranslation, useTranslation } from "react-i18next";
import Accordion from "../accordion/accordion";
import Mention from "../mention/mention";
import {
  displayResult,
  displayTolerance,
  displayMeasure,
  chooseMeasure,
} from "./utils";

import Antimagnetism from "../../assets/icons/anti-magnetism.png";
import Precision from "../../assets/icons/precision.png";
import Powerreserve from "../../assets/icons/power-reserve.png";
import Swissmade from "../../assets/icons/swiss-made.png";
import Waterproofness from "../../assets/icons/waterproofness.png";

import "./measures.scss";

const Measures = ({ measuresData, rightToLeft, isPluton }) => {
  const { t } = useTranslation();
  const isDe = localStorage.getItem("i18nextLng").match(/^(de)$/) && true;
  const precisionNote = isDe ? t("precisionNote").split('I') : [];

  //measureCosc = precision1, measureSwissmade = swissmade --- these 2 are not comming from the data
  const measurePj = chooseMeasure(measuresData, "0020-00009161"); //measuresData[5]; //precision2
  const measureDp = chooseMeasure(measuresData, "0020-00009157"); //precision3
  const measureDe = chooseMeasure(measuresData, "0020-00009168"); //precision4

  const measureS1 = chooseMeasure(measuresData, "0020-00009137"); //antimagnetism1 test passed
  const measureS2 = chooseMeasure(measuresData, "0020-00009144"); //antimagnetism2 test passed
  const measureEm = chooseMeasure(measuresData, "0020-00009151"); //antimagnetism3

  const measureW = chooseMeasure(measuresData, "0020-00007752"); //waterproofness test passed

  const measureRm = chooseMeasure(measuresData, "0020-00009173"); //powereserve test passed

  return (
    <div className="measures">
      <h2 className="heading">{t("metas")}</h2>
      <Accordion
        rightToLeft={rightToLeft}
        title={[
          <img src={Swissmade} className="swissFlag" alt="" />,
          t("swissMade"),
        ]}
        analyticsTitle="swiss made"
      >
        {t("swissMadeNote")}
        <Mention text={t("certified")} rightToLeft={rightToLeft} />
      </Accordion>

      <Accordion
        rightToLeft={rightToLeft}
        title={[<img src={Precision} alt=""></img>, t("precision")]}
        analyticsTitle="precision"
      >
        {
          isDe ? <> {precisionNote[0]}  <i>{precisionNote[1]}</i> {precisionNote[2]} </> : t("precisionNote")
        }
        <Mention text={t("certified")} border rightToLeft={rightToLeft} />

        {displayMeasure(measurePj[0]) && (
          <>
            <div className="marginTop">{t("precisionNote2")}</div>
            <Mention
              text={displayResult(measurePj[0], t("result"), t("unit"))}
              note={displayTolerance(
                measurePj[0],
                t("tolerance"),
                t("to"),
                t("unit")
              )}
              border
              rightToLeft={rightToLeft}
            />
          </>
        )}

        {displayMeasure(measureDp[0]) && (
          <>
            <div className="marginTop">{t("precisionNote3")}</div>
            <Mention
              text={displayResult(measureDp[0], t("result"), t("unit"))}
              note={displayTolerance(
                measureDp[0],
                t("tolerance"),
                t("to"),
                t("unit")
              )}
              border
              rightToLeft={rightToLeft}
            />
          </>
        )}

        {displayMeasure(measureDe[0]) && (
          <>
            <div className="marginTop">{t("precisionNote4")}</div>
            <Mention
              text={displayResult(measureDe[0], t("result"), t("unit"))}
              note={displayTolerance(
                measureDe[0],
                t("tolerance"),
                t("to"),
                t("unit")
              )}
              rightToLeft={rightToLeft}
            />
          </>
        )}
      </Accordion>

      <Accordion
        rightToLeft={rightToLeft}
        title={[<img src={Antimagnetism} alt=""></img>, t("magnetsim")]}
        analyticsTitle="anti magnetism"
      >
        {measureS1[0]?.Success && (
          <>
            {t("magnetismNote")}
            <Mention text={t("testPassed")} border rightToLeft={rightToLeft} />
          </>
        )}

        {measureS2[0]?.Success && (
          <>
            <div className="marginTop">{t("magnetismNote2")}</div>
            <Mention text={t("testPassed")} border rightToLeft={rightToLeft} />
          </>
        )}

        {displayMeasure(measureEm[0]) && (
          <>
            <div className="marginTop">{t("magnetismNote3")}</div>
            <Mention
              text={displayResult(measureEm[0], t("result"), t("unit"))}
              note={displayTolerance(
                measureEm[0],
                t("tolerance"),
                t("to"),
                t("unit")
              )}
              rightToLeft={rightToLeft}
            />
          </>
        )}
      </Accordion>

      {measureW[0]?.Success && (
        <Accordion
          rightToLeft={rightToLeft}
          title={[<img src={Waterproofness} alt=""></img>, t("waterProofness")]}
          analyticsTitle="waterproofness"
        >
          {t("waterProofnessNote")}
          <Mention text={t("testPassed")} rightToLeft={rightToLeft} />
        </Accordion>
      )}

      {measureRm[0]?.Success && (
        <Accordion
          rightToLeft={rightToLeft}
          title={[<img src={Powerreserve} alt=""></img>, t("powerReserve")]}
          analyticsTitle="power reserve"
        >
          {isPluton ? t("powerReserveNote").replace("70", "65") : t("powerReserveNote")}
          <Mention text={t("testPassed")} rightToLeft={rightToLeft} />
        </Accordion>
      )}
    </div>
  );
};

export default withTranslation()(Measures);

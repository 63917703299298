/**
 * InMobi Choice. Consent Manager Tag v3.0 (for TCF 2.2)  
 */

(function () {
  var host = window.location.hostname;
  var element = document.createElement('script');
  var firstScript = document.getElementsByTagName('script')[0];
  var url = 'https://cmp.inmobi.com'
    .concat('/choice/', 'sS7QAA4UE8wW4', '/', host, '/choice.js?tag_version=V3');
  var uspTries = 0;
  var uspTriesLimit = 3;
  element.async = true;
  element.type = 'text/javascript';
  element.src = url;

  firstScript.parentNode.insertBefore(element, firstScript);

  function makeStub() {
    var TCF_LOCATOR_NAME = '__tcfapiLocator';
    var queue = [];
    var win = window;
    var cmpFrame;

    function addFrame() {
      var doc = win.document;
      var otherCMP = !!(win.frames[TCF_LOCATOR_NAME]);

      if (!otherCMP) {
        if (doc.body) {
          var iframe = doc.createElement('iframe');

          iframe.style.cssText = 'display:none';
          iframe.name = TCF_LOCATOR_NAME;
          doc.body.appendChild(iframe);
        } else {
          setTimeout(addFrame, 5);
        }
      }
      return !otherCMP;
    }

    function tcfAPIHandler() {
      var gdprApplies;
      var args = arguments;

      if (!args.length) {
        return queue;
      } else if (args[0] === 'setGdprApplies') {
        if (
          args.length > 3 &&
          args[2] === 2 &&
          typeof args[3] === 'boolean'
        ) {
          gdprApplies = args[3];
          if (typeof args[2] === 'function') {
            args[2]('set', true);
          }
        }
      } else if (args[0] === 'ping') {
        var retr = {
          gdprApplies: gdprApplies,
          cmpLoaded: false,
          cmpStatus: 'stub'
        };

        if (typeof args[2] === 'function') {
          args[2](retr);
        }
      } else {
        if (args[0] === 'init' && typeof args[3] === 'object') {
          args[3] = Object.assign(args[3], { tag_version: 'V3' });
        }
        queue.push(args);
      }
    }

    function postMessageEventHandler(event) {
      var msgIsString = typeof event.data === 'string';
      var json = {};

      try {
        if (msgIsString) {
          json = JSON.parse(event.data);
        } else {
          json = event.data;
        }
      } catch (ignore) { }

      var payload = json.__tcfapiCall;

      if (payload) {
        window.__tcfapi(
          payload.command,
          payload.version,
          function (retValue, success) {
            var returnMsg = {
              __tcfapiReturn: {
                returnValue: retValue,
                success: success,
                callId: payload.callId
              }
            };
            if (msgIsString) {
              returnMsg = JSON.stringify(returnMsg);
            }
            if (event && event.source && event.source.postMessage) {
              event.source.postMessage(returnMsg, '*');
            }
          },
          payload.parameter
        );
      }
    }

    while (win) {
      try {
        if (win.frames[TCF_LOCATOR_NAME]) {
          cmpFrame = win;
          break;
        }
      } catch (ignore) { }

      if (win === window.top) {
        break;
      }
      win = win.parent;
    }
    if (!cmpFrame) {
      addFrame();
      win.__tcfapi = tcfAPIHandler;
      win.addEventListener('message', postMessageEventHandler, false);
    }
  };

  makeStub();

  var uspStubFunction = function () {
    var arg = arguments;
    if (typeof window.__uspapi !== uspStubFunction) {
      setTimeout(function () {
        if (typeof window.__uspapi !== 'undefined') {
          window.__uspapi.apply(window.__uspapi, arg);
        }
      }, 500);
    }
  };

  var checkIfUspIsReady = function () {
    uspTries++;
    if (window.__uspapi === uspStubFunction && uspTries < uspTriesLimit) {
      console.warn('USP is not accessible');
    } else {
      clearInterval(uspInterval);
    }
  };

  if (typeof window.__uspapi === 'undefined') {
    window.__uspapi = uspStubFunction;
    var uspInterval = setInterval(checkIfUspIsReady, 6000);
  }
})();

const reportWebVitals = () => {
  document.addEventListener("DOMContentLoaded", function (event) {
    window.__tcfapi("addEventListener", 2, async function (tcData, listenerSuccess) {
      console.log("makeStub -> listenerSuccess", listenerSuccess)
      console.log("makeStub -> tcData", tcData)
      console.log("makeStub -> window.digitalDataLayer", window.digitalDataLayer)
      if (listenerSuccess && tcData.eventStatus === "tcloaded") {
        console.log("makeStub -> tcData.eventStatus", tcData.eventStatus)
        console.log("makeStub -> window.digitalDataLayer", window.digitalDataLayer)
        console.log("makeStub -> window.dataLayer", window.dataLayer)

      }
      function getDigitalDataLayer() {
        try {
          return new Promise((resolve, reject) => {
            var tryDDL = setInterval(() => {
              const DataLayer = window.digitalDataLayer;
              console.log('getting window.digitalDataLayer : ', DataLayer);
              if (DataLayer) {
                clearInterval(tryDDL);
                console.log('dataLayer is defined, getDigitalDataLayer resolve!');
                resolve(DataLayer);
              }
            }, 50);
          });
        } catch (error) {
          console.log('getDigitalDataLayer catch-error :', error);
        }
      }


      if (listenerSuccess && (tcData.eventStatus === "useractioncomplete" || tcData.eventStatus === "tcloaded")) {
        console.log("makeStub -> tcData eventStatus", tcData.eventStatus)
        console.log("makeStub -> useractioncomplete!!!!!")
        if (!tcData.gdprApplies) {
          console.log("makeStub -> !tcData.gdprApplies")
          window._satellite.setVar("CC-Analyticsconsent", true);
          if (!!window.dtrum) {
            window.dtrum.enable()
          }
          console.log("satellite track Analytics");
          const DataLayer = await getDigitalDataLayer();
          console.log("digitalDataLayer", DataLayer);
          if (DataLayer) {
            var event = new CustomEvent("event-view-end");
            var obj = document.querySelector("body");
            obj.dispatchEvent(event);
          }
        } else {

          /**
           * IAB vendors.
           */
          var vendor_consent_id_list = [];
          var vendor_consent_id_list_str = "";
          Object.keys(tcData.vendor.consents).forEach(function (key) {
            if (tcData.vendor.consents[key]) {
              vendor_consent_id_list.push(key)
            }
          });
          vendor_consent_id_list_str = vendor_consent_id_list.join()
          console.log(" makeStub -> vendor_consent_id_list", tcData.vendor.consents)
          console.log(" makeStub -> window._satellite", window._satellite)

          window.__tcfapi("getNonIABVendorConsents", 2, function (nonIabConsent, nonIabSuccess) {
            console.log(" makeStub -> nonIabVendorConsents !!!")
            console.log(" makeStub -> nonIabConsent", nonIabConsent)
            console.log(" makeStub -> nonIabConsent.nonIabVendorConsents", nonIabConsent.nonIabVendorConsents)
            console.log(" makeStub -> nonIabSuccess", nonIabSuccess)
            var analyticsConsent = false;
            var DYconsent = false;

            const satelliteSetting = async () => {
              try {
                console.log("customVars -> analyticsConsent", analyticsConsent);

                // 500ms delay so _satellite code has time to init properly (AppMeasurement.js, etc...)
                await new Promise(r => setTimeout(r, 1500));

                window._satellite.setVar("CC-Analyticsconsent", analyticsConsent);

                console.log("value of CC-Analyticsconsent", window._satellite.getVar("CC-Analyticsconsent"));
                if (!!window.dtrum) {
                  window.dtrum[DYconsent ? "enable" : "disable"]()
                }
                console.log("satellite track Analytics");
                const DataLayer = await getDigitalDataLayer();
                console.log("digitalDataLayer", DataLayer);
                if (DataLayer) {
                  var event = new CustomEvent("event-view-end");
                  var obj = document.querySelector("body");
                  obj.dispatchEvent(event);
                }
                console.log("event-view-end");
              } catch (error) {
                console.log("catch-error", error);
              }
            }

            if (nonIabSuccess && nonIabConsent.nonIabVendorConsents) {
              var nonIABVendorsIds = nonIabConsent.nonIabVendorConsents;
              console.log("makeStub -> nonIABVendorsIds", nonIABVendorsIds)

              if (nonIABVendorsIds !== undefined) {
                analyticsConsent = nonIABVendorsIds[9];

                if (window._satellite) {
                  satelliteSetting();
                } else {
                  const interval = window.setInterval(() => {
                    if (window._satellite) {
                      satelliteSetting();
                      clearInterval(interval);
                    }
                  }, 300);
                }
              }
            }
          });
        }
      }
    });
  });
};

export default reportWebVitals;
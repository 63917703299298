export const measures = (measuresData) => {
    try {

        if (measuresData) {
            //array of measures which DOESNT contain any value in measuresData[].values but contain Success
            const measuresWithoutValue = [
                "0020-00009173",
                "0020-00009137",
                "0020-00007752",
                "0020-00009144",
            ];
            //array of measures which contain any value in measuresData[].values
            const measuresWithValue = [
                "0020-00009168",
                "0020-00009161",
                "0020-00009157",
                "0020-00009151",
            ];

            const measuresCounter = (() => {
                //count how many measures are empty
                let counter = 0;
                for (const measure of measuresData) {
                    if (
                        //count for measures which contain values
                        (measuresWithValue.indexOf(measure?.MeasureId) >= 0 &&
                            (measure?.Value?.length <= 0 ||
                                measure?.ValueHigh?.length <= 0 ||
                                measure?.ValueLow?.length <= 0 ||
                                measure?.Success !== true)) ||
                        // count for measures which contain only "Success": "true"
                        (measuresWithoutValue.indexOf(measure?.MeasureId) >= 0 &&
                            measure?.Success !== true)
                    )
                        counter += 1;
                }
                return counter;
            });

            return (measuresData && measuresData?.length <= 5 // total count for measures is 8, if there are more then 3 measures missing => hide
                ? false
                : measuresCounter() >= 3 // if there is any measure which doesnt contain value or success => considered as missing
                    ? false
                    : true);

        } else return false;

    } catch (err) {
        console.log("measures - catch - err :", err);
    }
};
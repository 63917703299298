import { useEffect } from "react";
import { withTranslation, useTranslation } from "react-i18next";
import Loader from "../../components/loader/loader";

import BasicLogo from "../../assets/images/basicLogo.svg";
import Background from "../../assets/images/background.png";
import { setLanguageCode } from "../../utils/helpers/functions.helper";
import "./loading.scss";

function Loading({ loading, cardType }) {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (cardType && cardType.toLowerCase() === "sc") {
      setLanguageCode(i18n);
    }
  }, [i18n]);


  return (
    <>
      <div className="loading">
        <img src={BasicLogo} className="basicLogo" alt="logo" />
        <div className="smallTitleLight"> {t("welcome")}</div>
        <div className="mediumTitle">tudor</div>
        <div className="bigTitle">watch id</div>
        <Loader />
        <img src={Background} className="background" alt="logo" />
      </div>
    </>
  );
}

export default withTranslation()(Loading);

export const getServiceString = (serviceCode, t) => {
  let serviceString;
  switch (serviceCode) {
    case "ST01":
      serviceString = t("ST01");
      break;
    case "ST02":
      serviceString = t("ST02");
      break;
    case "ST03":
      serviceString = "Quick service";
      break;
    case "ST04":
      serviceString = "Restoration";
      break;
    case "ST05":
      serviceString = "Service outsourced to other affiliate";
      break;
    case "ST06":
      serviceString = "Service Declined by Rolex";
      break;
    case "STM01":
      serviceString = "Regular terms";
      break;
    case "STM02":
      serviceString = "Under sales guarantee domestic";
      break;
    case "STM03":
      serviceString = "Under sales guarantee abroad";
      break;
    case "STM04":
      serviceString = "Under service guarantee domestic";
      break;
    case "STM05":
      serviceString = "Under service guarantee abroad";
      break;
    case "STM06":
      serviceString = "Out of guarantee free of charge";
      break;
    case "STM07":
      serviceString = "New Goods (Affiliate stock) ";
      break;
    case "STM08":
      serviceString = "New Goods (Authorised Retailer stock)";
      break;
    case "STM09":
      serviceString = "Administrative work";
      break;
    case "SD01":
      serviceString = "Quote declined by customer";
      break;
    case "SD02":
      serviceString = "Full acceptance";
      break;
    case "SD03":
      serviceString = "Partial acceptance";
      break;
    case "SD04":
      serviceString = "Not in original condition, modified";
      break;
    case "SD05":
      serviceString = "Old model, unrepairable, no spare parts";
      break;
    case "SD06":
      serviceString = "Partially or entirely counterfeit";
      break;
    case "SD07":
      serviceString = "For other reason";
      break;
    default:
      return (serviceString = serviceCode);
  }
  return serviceString;
};

import React, { useState } from "react";
import { analytics_trigger_event } from "../../analytics/analytics";
import "./accordion.scss";

const Accordion = ({ title, analyticsTitle, children, rightToLeft }) => {
  const [isOpen, setOpen] = useState(false);

  const handleClick = () => {
    analytics_trigger_event({
      eventName: "outlink",
      data: {
        triggerName: "panelClicked",
        panelName: analyticsTitle,
      },
    });
    setOpen(!isOpen);
  };

  return (
    <>
      <button
        aria-expanded={isOpen ? true : false}
        aria-controls={title}
        className={`accordionTitle ${isOpen ? "open" : ""} ${rightToLeft ? "accordionTitleRTL" : ""
          }`}
        onClick={() => (isOpen ? setOpen(!isOpen) : handleClick())}
      >
        {title}
      </button>
      <div className={`accordionItem ${!isOpen ? "collapsed" : ""} ${rightToLeft ? "accordionTitleRTL" : ""
        }`} aria-hidden={isOpen ? false : true}>
        <div className="accordionContent">{children}</div>
      </div>
    </>
  );
};

export default Accordion;
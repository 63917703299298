import React from "react";
import Checkmark from "../../assets/icons/checkmark.png";

import "./mention.scss";

const Mention = ({ text, note, border, rightToLeft }) => {
  return (
    <>
      <div
        className={`mention ${border ? "border" : ""} ${
          rightToLeft ? "mentionRTL" : ""
        }`}
      >
        <img src={Checkmark} alt=""></img>
        {text}
        <div className="smallText">{note}</div>
      </div>
    </>
  );
};

export default Mention;
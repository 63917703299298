import React from "react";
import "./demoLabel.scss";

function DemoLabel() {
  const hostName = window.location.hostname;
  function getEnvironment() {
    let env;
    switch (hostName) {
      case "dev.watchid.tudorwatch.com":
        env = "DEV";
        break;
      case "ci.watchid.tudorwatch.com":
        env = "TEST";
        break;
      case "pp.watchid.tudorwatch.com":
        env = "PPROD";
        break;
      default:
        env = "";
    }
    return env;
  }

  return (
    <>
      {hostName === "watchid.tudorwatch.com" ? (
        ""
      ) : (
        <div className="demo">
          FOR TESTING PURPOSE ONLY ({getEnvironment()})
        </div>
      )}
    </>
  );
}

export default DemoLabel;

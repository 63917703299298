export const getTranslation = (businessData, type) => {
  const translationsArray = businessData[0]?.Translations;
  const langVal = businessData[0]?.Language;

  return translationsArray?.map((translation) => {
    const HE_TDR_MODEL_NAME =
        langVal === "he" &&
        type === translation.Type &&
        type === "TDR_MODEL_NAME" ? translation.Translation.split(" ") : [];

    return (
      type === translation.Type && (
        type === "TDR_MODEL_NAME" && langVal === "he" ?
          HE_TDR_MODEL_NAME && HE_TDR_MODEL_NAME.length && HE_TDR_MODEL_NAME.map(modelName => <>
            <h2
              key={translation.Type}
            >{modelName} </h2>
          </>)
          : type === "TDR_MODEL_NAME" ?
            <h2
              key={translation.Type}
              dangerouslySetInnerHTML={{ __html: translation.Translation }}
            ></h2> 
          :
            <div
              key={translation.Type}
              dangerouslySetInnerHTML={{ __html: translation.Translation }}
            ></div>
      )
    );
  });
};

import { withTranslation, useTranslation } from "react-i18next";
import DataLayer from "../../analytics/dataLayer";
import DemoLabel from "../../components/demoLabel/demoLabel";
import BasicLogo from "../../assets/images/basicLogo.svg";
import Background from "../../assets/images/background.png";
import "./error.scss";

const ErrorScreen = ({ status, loading, correlation }) => {
  const { t } = useTranslation();
  const correlationId = correlation.slice(24, 36);
  return (
    <>
      <DataLayer watchData="" status={status} loading={loading} />
      <DemoLabel />
      <div className="errorScreen">
        <img src={BasicLogo} className="basicLogo" alt={t("img.basicLogo")} />
        <div className="errorContent">
          <div className="smallTitle">
            {
              status === 500
                ? t("authenticationServiceUnreachable") :
              status === 400 ?
                  t("unableToReadCard") :
              status === 409 ?
                  t("cardSingleUsage") :
              status === 423 ?
                  t("cardNoLongerAssociated") :
                  t("unableToReadCard")
            }
          </div>
        </div>
        <div className="correlationBlock">
          <div className="errorId">
            {
            status === 500 ? "ERR01" : 
            status === 400 ? "ERR02" : 
            status === 409 ? "WARNING03" : 
            status === 423 ? "INFO01" : "ERR02"
            }</div>
          <div className="errorId">{correlationId}</div>
        </div>
        <img src={Background} className="background" alt="logo" />
      </div>
      <DataLayer pageBottom />
    </>
  );
};

export default withTranslation()(ErrorScreen);

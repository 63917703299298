import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  useHistory,
  useLocation,
} from "react-router-dom";
import {
  useTrackEvent,
  withAITracking,
} from "@microsoft/applicationinsights-react-js";
import { reactPlugin, appInsights } from "./analytics/appInsights";
import ErrorScreen from "./pages/errorScreen/error";
import AssociatePage from "./pages/associatePage/associatePage";
import Loading from "./pages/loading/loading";
import Product from "./pages/product/product";
import configData from "./config.json";
import { preferedLanguage } from "./components/languageSwitcher/preferedLanguage";
import { measures } from './utils/helpers/measures.helpers';
import { isRightToLeft } from './utils/helpers/functions.helper';
import Helmet from "./components/helmet/helmet";

function Content() {
  const [watchData, setWatchData] = useState(null);
  const trackFetching = useTrackEvent(appInsights, "Fetch", watchData);
  const [businessData, setbusinessData] = useState([]);
  const [association, setAssociation] = useState(false);
  const [generatedCode, setGeneratedCode] = useState("");
  const [loading, setLoading] = useState(true);
  const [scrollLock, setScrollLock] = useState(false);
  const [error, setError] = useState("");
  const [status, setStatus] = useState("");
  const [token, setToken] = useState(sessionStorage.getItem("bearer"));
  const [correlation, setCorrelation] = useState("");
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const rightToLeft = isRightToLeft();

  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = configData.analyticsUrl;
    script.async = true;
    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const ekid = params.get("ekid");
    const enpd = params.get("enpd");
    const enfd = params.get("enfd");
    const cmac = params.get("cmac");
    const rid = params.get("rid");
    const p = params.get("p");
    const c = params.get("c");
    const m = params.get("m");
    const originalUrl = params.get("original_url");
    const url1 = `${configData.azureFunctionEndpoint}/Authenticate/v1?ekid=${ekid}&enpd=${enpd}&enfd=${enfd}&cmac=${cmac}&subscription-key=${configData.subscriptionkey}`;
    const url2 = `${configData.azureFunctionEndpoint}/Authenticate/v2?rid=${rid}&p=${p}&c=${c}&m=${m}&subscription-key=${configData.subscriptionkey}&original_url=${originalUrl}`;
    const cardType = params.get("type");
    const fetchTokenUrl = !ekid ? url2 : url1;
    trackFetching({});
    if (
      !token &&
      (ekid === null || enpd === null || enfd === null || cmac === null) &&
      (rid === null || p === null || c === null || m === null)
    ) {
      window.location.replace(configData.redirectUrl);
    }

    if (
      (ekid !== null && enpd !== null && enfd !== null && cmac !== null) ||
      (rid !== null && p !== null && c !== null && m !== null)
    ) {
      fetch(fetchTokenUrl)
        .then((response) => {
          if (response.status === 200) {
            for (const pair of response.headers.entries()) {
              if (pair[0] === "authorization") {
                sessionStorage.setItem("bearer", pair[1]);
                setToken(pair[1]);
              }
            }
            clearParams(false);
          } else {
            for (const pair of response.headers.entries()) {
              if (pair[0] === "x-correlation-id") {
                setCorrelation(pair[1]);
              }
            }
            clearParams(true);
            setStatus(response.status);
            setError(true);
            setLoading(false);
            return response.text();
          }
        })
        .then((data) => {
          if (data) {
            setError(true);
            setLoading(false);
          }
        })
        .catch((e) => {
          setError(true);
          setLoading(false);
          console.log(e);
        });
    }
    const fetchWatchDataUrl = `${configData.azureFunctionEndpoint}/WatchData/?subscription-key=${configData.subscriptionkey}`;
    const fetchGenerateCodeUrl = `${configData.azureFunctionEndpoint}/GenerateCode/?subscription-key=${configData.subscriptionkey}`;

    if (token && ekid === null && rid === null) {
      fetch(fetchWatchDataUrl, {
        headers: {
          authorization: token,
        },
      })
        .then((response) => {
          if (response.status === 404) {
            if (cardType && cardType.toLowerCase() === "sc") {
              setAssociation(true);
              fetch(fetchGenerateCodeUrl, {
                headers: {
                  authorization: token,
                  "X-Brand": "TUDOR",
                  "X-CardType": "ServiceCard",
                },
              })
                .then((res) => {
                  clearParams(true);
                  if (!res.ok) {
                    setAssociation(false);
                    setError(true);
                    for (const pair of res.headers.entries()) {
                      if (pair[0] === "x-correlation-id") {
                        setCorrelation(pair[1]);
                      }
                    }
                    setLoading(false);
                    setStatus(res.status);
                    return res.text();
                  }
                  setStatus(res.status);
                  return res.json();
                })
                .then(
                  (data) => {
                    if (data) {
                      setGeneratedCode(JSON.parse(data));
                      setLoading(false);
                    }
                  },
                  (error) => {
                    console.log("generated code error", error);
                  }
                );
            } else {
              for (const pair of response.headers.entries()) {
                if (pair[0] === "x-correlation-id") {
                  setCorrelation(pair[1]);
                }
              }
              setStatus(404);
              window.location.replace(configData.redirectUrl);
            }
          } else if (!response.ok) {
            for (const pair of response.headers.entries()) {
              if (pair[0] === "x-correlation-id") {
                setCorrelation(pair[1]);
              }
            }
            clearParams(true);
            setStatus(response.status);
            setError(true);
            setLoading(false);
            return response.text();
          } else return response.json();
        })
        .then((data) => {
          if (data) {
            clearParams(true);
            setWatchData(JSON.parse(data));
            setLoading(false);
          }
        })
        .catch((e) => {
          setError(true);
          setLoading(false);
          console.log(e);
        });
    }
  }, [token, history]);

  useEffect(() => {
    if (watchData !== null) {
      const rmc = watchData?.Configuration?.Rmc
        ? watchData?.Configuration?.Rmc
        : watchData?.Configuration?.Model;
      const fetchBusinessDataUrl = `${configData.azureFunctionEndpoint
        }/Translations?language=${preferedLanguage() === "zh-Hant"
          ? "zh-hant"
          : preferedLanguage() === "zh-Hans"
            ? "zh-hans"
            : preferedLanguage()
        }&rmc=${rmc}&subscription-key=${configData.subscriptionkey}`;
      fetch(fetchBusinessDataUrl, {
        headers: {
          authorization: token,
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          setbusinessData(JSON.parse(data));
          setLoading(false);
        })
        .catch((e) => {
          console.log(e);
          setLoading(false);
        });
    }
  }, [token, watchData]);

  const clearParams = (isRemoveType) => {

    queryParams.delete("ekid");
    queryParams.delete("enpd");
    queryParams.delete("enfd");
    queryParams.delete("cmac");

    queryParams.delete("rid");
    queryParams.delete("p");
    queryParams.delete("c");
    queryParams.delete("m");
    queryParams.delete("original_url");


    if (isRemoveType) {
      queryParams.delete("type");
    }

    history.replace({
      search: queryParams.toString(),
    });
  };

  return (
    <div className={`${scrollLock && "scrollLock"} ${rightToLeft ? "rtl" : ""}`}>
      {loading ? (
        <Loading loading={loading} cardType={queryParams.get("type")} />
      ) : error && !association ? (
        <ErrorScreen
          status={status}
          correlation={correlation}
          loading={loading}
        />
      ) : association ? (
        <AssociatePage generatedCode={generatedCode} status={status} loading={loading} />
      ) : (
        <Product
          scrollLock={scrollLock}
          setScrollLock={setScrollLock}
          lightTheme={
            watchData?.Configuration?.Rmc?.toUpperCase() === "M79210CNU-0001"
              ? false
              : watchData?.Configuration?.Rmc?.toUpperCase() ===
                "M79210CNU-0002"
                ? false
                : true
          }
          rightToLeft={rightToLeft}
          watchData={watchData}
          businessData={businessData}
          loading={loading}
          displayMetas={
            watchData?.Configuration?.Rmc?.toUpperCase() === "M79210CNU-0001" || measures(watchData.Measures)
              ? true
              : watchData?.Configuration?.Rmc?.toUpperCase() ===
                "M7983/001U-0001"
                ? true
                : false
          }
        />
      )}
    </div>
  );
}

function App() {
  return (
    <>
      <Helmet />
      <Router>
        <Route component={Content} />
      </Router>
    </>
  );
}

export default withAITracking(reactPlugin, App);
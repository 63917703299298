import "./loader.scss";

const Loader = () => {
  return (
    <div className="loadingio-spinner-spinner-d79520ez52s">
      <div className="ldio-rury1rispbo">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default Loader;

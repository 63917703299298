import { preferedLanguage } from "../languageSwitcher/preferedLanguage";

export const formatDecimal = (value) => {
  const selectedLanguage =
    preferedLanguage() === "zh_CN"
      ? "zh"
      : preferedLanguage() === "zh_TW"
      ? "zh"
      : preferedLanguage();
  return parseFloat(value).toLocaleString(selectedLanguage, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const displayResult = (result, text, unitText) => {
  return (
    <>
      {text} {formatDecimal(result.Value)} {unitText}
    </>
  );
};

export const displayTolerance = (tolerance, text, toText, unitText) => {
  return (
    <>
      {text} {formatDecimal(tolerance.ValueLow)} {toText}
      {formatDecimal(tolerance.ValueHigh)} {unitText}
    </>
  );
};

export const chooseMeasure = (measuresData, measureCode) => {
  return measuresData?.filter((measure) => {
    return measure?.MeasureId === measureCode ? measure : null;
  });
};

export const displayMeasure = (measure) => {
  //hide measure when one of the values is missing
  return (measure?.Value?.length > 0) &
    (measure?.ValueHigh?.length > 0) &
    (measure?.ValueLow?.length > 0) &
    measure?.Success
    ? true
    : false;
};

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

const currentLanguageCode = (window.navigator.language).toLowerCase();

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  });

if (currentLanguageCode) {
  switch (currentLanguageCode.toLowerCase()) {
    case 'zh-tw':
      localStorage.setItem("i18nextLng", "zh-Hant");
      i18n.changeLanguage("zh-Hant");
      break;
    case 'zh-cn':
      localStorage.setItem("i18nextLng", "zh-Hans");
      i18n.changeLanguage("zh-Hans");
      break;
    default:
      i18n.changeLanguage();
      break;
  }
}


export default i18n;

import React from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import "./countdownCircleTimer.scss";

const renderTime = ({ remainingTime }) => {
  if (remainingTime === 0) {
    return <div className="timer timerExpier">!</div>;
  }

  return (
    <div className="timer">
      <div className="value">{remainingTime}</div>
      <div className="text">sec.</div>
    </div>
  );
};

const CountDownCircleTimer = () => {
  return (
    <div className="timer-wrapper">
      <CountdownCircleTimer
        isPlaying
        duration={30}
        colors={"#8f8f8f"}
        size={70}
        strokeWidth={4}
        trailColor={"#FFF"}
        onComplete={() => ({ shouldRepeat: false, delay: 1 })}
      >
        {renderTime}
      </CountdownCircleTimer>
    </div>
  );
};

export default CountDownCircleTimer;

import React from "react";
import { withTranslation, useTranslation } from "react-i18next";
import Accordion from "../accordion/accordion";
import { getTranslation } from "./utils";

import Case from "../../assets/icons/specifications-case.svg";
import Movement from "../../assets/icons/specifications-movement.svg";
import Bracelet from "../../assets/icons/specifications-bracelet.svg";
import SpecWaterproofness from "../../assets/icons/specifications-waterproofness.png";
import SpecBezel from "../../assets/icons/specifications-bezel.png";
import SpecCrystal from "../../assets/icons/specifications-crystal.png";
import SpecDial from "../../assets/icons/specifications-dial.png";
import SpecPowerreserve from "../../assets/icons/specifications-power-reserve.png";
import SpecWindingcrown from "../../assets/icons/specifications-winding-crown.png";

import redCase from "../../assets/icons/red/specifications-case.svg";
import redMovement from "../../assets/icons/red/specifications-movement.svg";
import redBracelet from "../../assets/icons/red/specifications-bracelet.svg";
import redSpecWaterproofness from "../../assets/icons/red/specifications-waterproofness.svg";
import redSpecBezel from "../../assets/icons/red/specifications-bezel.svg";
import redSpecCrystal from "../../assets/icons/red/specifications-crystal.svg";
import redSpecDial from "../../assets/icons/red/specifications-dial.svg";
import redSpecPowerreserve from "../../assets/icons/red/specifications-power-reserve.svg";
import redSpecWindingcrown from "../../assets/icons/red/specifications-winding-crown.svg";

import "./specifications.scss";

const Specifications = ({ businessData, rightToLeft, lightTheme }) => {
  const { t } = useTranslation();

  const caseData = getTranslation(businessData, "TDR_CASE_TITLE");
  const bezel = getTranslation(businessData, "TDR_CASE_BEZEL");
  const windingCrown = getTranslation(businessData, "TDR_CASE_WINDING_CROWN");
  const waterproofness = getTranslation(
    businessData,
    "TDR_CASE_WATER_RESISTANCE"
  );
  const dial = getTranslation(businessData, "TDR_DIAL");
  const braceletAdditional = getTranslation(
    businessData,
    "TDR_BRACELET_ADDITIONAL"
  );
  const bracelet = getTranslation(businessData, "TDR_BRACELET_TITLE");
  const braceletMaterial = getTranslation(businessData, "BRACELET_MATERIAL");
  const crystal = getTranslation(businessData, "TDR_CASE_CRYSTAL");
  const movement = getTranslation(businessData, "TDR_MOVEMENT_CALIBRE");
  const powerReserve = getTranslation(businessData, "TDR_MOVEMENT_AUTONOMY");

  return (
    <div
      className={`specificationsBlackWrapper ${
        rightToLeft ? "specificationsBlackWrapperRTL" : ""
      } ${lightTheme ? "specificationsLightWrapper" : ""}`}
    >
      <h2 className="xsTitle">{t("specifications")}</h2>
      <Accordion
        title={[
          <span>
            <img src={lightTheme ? redCase : Case} alt=""></img>
          </span>,
          t("case"),
        ]}
        analyticsTitle="case"
        rightToLeft={rightToLeft}
      >
        <div className="description specificationsBlack">{caseData}</div>
        <div
          className={`specificationsBlack ${
            rightToLeft ? "specificationsBlackRTL" : ""
          }`}
        >
          <div className="currentText">
            <span>
              <img
                src={lightTheme ? redSpecWaterproofness : SpecWaterproofness}
                alt=""
              ></img>
            </span>
            {t("waterProofness")}
          </div>
          <div className="description">{waterproofness}</div>
        </div>
        <div
          className={`specificationsBlack ${
            rightToLeft ? "specificationsBlackRTL" : ""
          }`}
        >
          <div className="currentText">
            <span>
              <img src={lightTheme ? redSpecBezel : SpecBezel} alt=""></img>
            </span>
            {t("bezel")}
          </div>
          <div className="description">{bezel}</div>
        </div>
        <div
          className={`specificationsBlack ${
            rightToLeft ? "specificationsBlackRTL" : ""
          }`}
        >
          <div className="currentText">
            <span>
              <img src={lightTheme ? redSpecDial : SpecDial} alt=""></img>
            </span>
            {t("dial")}
          </div>
          <div className="description">{dial}</div>
        </div>
        <div
          className={`specificationsBlack ${
            rightToLeft ? "specificationsBlackRTL" : ""
          }`}
        >
          <div className="currentText">
            <span>
              <img src={lightTheme ? redSpecCrystal : SpecCrystal} alt=""></img>
            </span>
            {t("crystal")}
          </div>
          <div className="description"> {crystal}</div>
        </div>
      </Accordion>

      <Accordion
        rightToLeft={rightToLeft}
        title={[
          <span>
            <img src={lightTheme ? redMovement : Movement} alt=""></img>
          </span>,
          t("movement"),
        ]}
        analyticsTitle="movement"
      >
        <div className="description specificationsBlack">{movement}</div>

        <div
          className={`specificationsBlack ${
            rightToLeft ? "specificationsBlackRTL" : ""
          }`}
        >
          <div className="currentText">
            <span>
              <img
                src={lightTheme ? redSpecPowerreserve : SpecPowerreserve}
                alt=""
              ></img>
            </span>
            {t("powerReserve")}
          </div>
          <div className="description">{powerReserve}</div>
        </div>
        <div
          className={`specificationsBlack ${
            rightToLeft ? "specificationsBlackRTL" : ""
          }`}
        >
          <div className="currentText">
            <span>
              <img
                src={lightTheme ? redSpecWindingcrown : SpecWindingcrown}
                alt=""
              ></img>
            </span>
            {t("windingCrown")}
          </div>
          <div className="description ">{windingCrown}</div>
        </div>
      </Accordion>

      <Accordion
        rightToLeft={rightToLeft}
        title={[
          <span>
            <img src={lightTheme ? redBracelet : Bracelet} alt=""></img>
          </span>,
          t("bracelet"),
        ]}
        analyticsTitle="bracelet"
      >
        <div className="description specificationsBlack">{bracelet}</div>
        {braceletMaterial === false ? (
          <div className="description specificationsBlack">
            {braceletMaterial}
          </div>
        ) : (
          ""
        )}
        <div className="description specificationsBlack">
          {braceletAdditional}
        </div>
      </Accordion>
    </div>
  );
};

export default withTranslation()(Specifications);

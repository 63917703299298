import { useEffect } from "react";
import { fireViewStartCustomEvent, fireViewEndCustomEvent } from "./analytics";
import loadDataLayer from "./loadDataLayer";

const DataLayer = ({ businessData, watchData, status, loading, pageBottom }) => {

  useEffect(() => {

    let time;
    if (!pageBottom && !watchData) {
      fireViewStartCustomEvent({ detail: "" });
      loadDataLayer("tdrModelName", watchData, status);
    }

    if (pageBottom) {
      if (window?.__satelliteLoaded) {
        fireViewEndCustomEvent();
      } else {
        time = window.setInterval(() => {
          if (window?.__satelliteLoaded) {
            fireViewEndCustomEvent();
            clearInterval(time);
          }
        }, 300);
      }

      return () => clearInterval(time);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {

    try {

      const tdrModelName = businessData?.length ? businessData[0]?.TranslationsForEnglish?.filter((item) => item?.Type?.includes('TDR_MODEL_NAME'))[0]?.Translation : "";

      if (!pageBottom && businessData) {
        fireViewStartCustomEvent({ detail: "" });
        loadDataLayer(tdrModelName, watchData, status);
      }

    } catch (e) { console.log("\n\n\n e - tdrModelName", e) }

  }, [businessData, status, loading, pageBottom, watchData]);

  return null;
};

export default DataLayer;
import React from "react";
import { withTranslation, useTranslation } from "react-i18next";
import { preferedLanguage } from "./preferedLanguage";

import "./languageSwitcher.scss";

const LanguageSwitcher = ({
  rightToLeft,
  error,
  setLanguagesOpen,
  isLanguagesOpen,
}) => {
  const { i18n } = useTranslation();

  const handleClick = () => {
    error ? setLanguagesOpen(!isLanguagesOpen) : window.location.reload();
  };

  const languagesArray = [
    { name: "English", code: "en" },
    { name: "Français", code: "fr" },
    { name: "Italiano", code: "it" },
    { name: "Deutsch", code: "de" },
    { name: "Español", code: "es" },
    { name: "Português", code: "pt" },
    { name: "简体中文", code: "zh-Hans" },
    { name: "繁體中文", code: "zh-Hant" },
    { name: "日本語", code: "ja" },
    { name: "한국어", code: "ko" },
    { name: "Русский", code: "ru" },
    { name: "Türkçe", code: "tr" },
    { name: "العربية", code: "ar" },
    { name: "עברית", code: "he" },
    { name: "Nederlands", code: "nl" },
  ];
  return (
    <div id="langNav" aria-hidden={isLanguagesOpen ? false : true}>
      <div
        id={rightToLeft ? "sliderRTL" : "slider"}
        className={isLanguagesOpen ? "slide-in" : ""}
      >
        {!rightToLeft && <ul className="navigation2"></ul>}
        <div className="navigation">
          <ul className="textWrapper">
            {languagesArray.map((language, index) => (
              <li
                key={language.code}
                lang={language.code}
                aria-setsize={languagesArray.length}
                aria-posinset={index + 1}
                className={
                  preferedLanguage() === language.code
                    ? "selectedLanguage"
                    : "languageItem"
                }
                onClick={() =>
                  i18n.changeLanguage(language.code) & handleClick()
                }
              >
                <button type="button"
                  aria-label={language.name}
                  lang={language.code}
                  aria-current={
                    preferedLanguage() === language.code
                      ? true
                      : false
                  }
                  tabindex="-1"
                >
                  {language.name}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(LanguageSwitcher);

import { useState } from "react";
import { withTranslation, useTranslation } from "react-i18next";
import DemoLabel from "../../components/demoLabel/demoLabel";
import BasicLogo from "../../assets/images/basicLogo.svg";
import Background from "../../assets/images/tudorServiceCardBackground.png";
import CountDownCircleTimer from "../../components/countdownCircleTimer/countdownCircleTimer";
import "./associatePage.scss";
import DataLayer from "../../analytics/dataLayer";

const AssociatePage = ({ generatedCode, status, loading }) => {
  const [contentFlag, setContentFlag] = useState(true);
  const [generatedCodeVal, setGeneratedCodeVal] = useState("");
  const { t, i18n } = useTranslation();

  if (generatedCode) {
    setTimeout(() => {
      setContentFlag(false);
      setGeneratedCodeVal(t("sessionExpired"));
    }, 30003);
  }

  return (
    <>
      <DataLayer watchData="" status={status} loading={loading} />
      <DemoLabel />
      <div className="associateScreen">
        <img className="basicLogo" alt={t("img.basicLogo")} src={BasicLogo} />
        <div className="textWrapperBody">
          <div className="bigTitle">
            {generatedCodeVal ? t("weAreSorry") : t("tudorPairingMode")}
          </div>
          <div className="smallTitle">
            {generatedCode && generatedCode?.DigitCode
              ? contentFlag
                ? t("otpContent")
                : t("expireContent")
              : "Oops, something went wrong. Please try again later."}
          </div>
          {generatedCode && generatedCode?.DigitCode ? (
            <>
              {generatedCodeVal ? (
                <>
                  <div className="sessionMsg">{generatedCodeVal}</div>
                  {/* <img className="warningIcon" src={warningIcon} alt="logo" /> */}
                </>
              ) : generatedCode ? (
                <>
                  <div className="numbers">
                    {generatedCode?.DigitCode && (
                      <>
                        <span className="digit">
                          {generatedCode?.DigitCode.charAt(0)}
                        </span>
                        <span className="digit">
                          {generatedCode?.DigitCode.charAt(1)}
                        </span>
                        <span className="digit">
                          {generatedCode?.DigitCode.charAt(2)}
                        </span>
                        <span className="digit">
                          {generatedCode?.DigitCode.charAt(3)}
                        </span>
                        <span className="digit">
                          {generatedCode?.DigitCode.charAt(4)}
                        </span>
                        <span className="digit">
                          {generatedCode?.DigitCode.charAt(5)}
                        </span>
                      </>
                    )}
                  </div>
                  {/* <CountDownCircleTimer /> */}
                </>
              ) : (
                "Please try again later"
              )}
              <CountDownCircleTimer />
            </>
          ) : (
            ""
          )}
        </div>
        <img src={Background} className="background" alt="logo" />
      </div>
      <DataLayer pageBottom />
    </>
  );
};

export default withTranslation()(AssociatePage);

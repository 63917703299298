import moment from "moment";
import Moment from "react-moment";
export const displayGuarantee = (warrantyEndDate, underText, validText, format) => {
  const today = new Date();
  const warrantyEndDateFormated = new Date(warrantyEndDate);

  return (
    <>
      {moment(warrantyEndDateFormated).isSameOrAfter(today, "day") ? (
        <div className="underNote">
          <strong>{underText} </strong>
          <div>
            {validText}{' '}
            <Moment format={format}>
              {warrantyEndDate}
            </Moment>
          </div>
        </div>
      ) : null}
    </>
  );
};

import React, { useState } from "react";
import { withTranslation, useTranslation } from "react-i18next";
import { analytics_trigger_event } from "../../analytics/analytics";
import { preferedLanguage } from "../languageSwitcher/preferedLanguage";
import LanguageSwitcher from "../../components/languageSwitcher/languageSwitcher";
import YourWatch from "../../assets/icons/navigation/your-watch.svg";
import ServiceCenter from "../../assets/icons/navigation/service-center.svg";
import TudorWatch from "../../assets/icons/navigation/tudor-watch.svg";
import Globe from "../../assets/icons/navigation/globe.svg";
import "./navigation.scss";

const Navigation = ({
  displayYourWatch,
  lightTheme,
  rightToLeft,
  error,
  setScrollLock,
  scrollLock,
}) => {
  const { t } = useTranslation();
  const [navOpen, setNavOpen] = useState(false);
  const [isLanguagesOpen, setLanguagesOpen] = useState(false);
  const selectedLanguage =
    preferedLanguage() === "zh-Hant"
      ? "zh-hant"
      : preferedLanguage() === "zh-Hans"
        ? "zh-hans"
        : preferedLanguage();

  const handleClick = (clickUrl) => {
    analytics_trigger_event({
      eventName: "outlink",
      data: {
        outlinkURL: clickUrl,
        outlinkName: "tudorwatch.com",
      },
    });
  };

  return (
    <>
      <button
        aria-expanded={navOpen && !isLanguagesOpen ? true : false}
        aria-controls="navMenu"
        aria-label="menu"
        className={rightToLeft ? "navIconRTL" : "navIcon"}
        onClick={() =>
          setNavOpen(!navOpen) &
          setLanguagesOpen(
            isLanguagesOpen ? !isLanguagesOpen : isLanguagesOpen
          ) &
          setScrollLock(!scrollLock)
        }
      >
        <div
          className={
            navOpen ? "cross" : lightTheme ? "hamburgerLight" : "hamburger"
          }
        >
        </div>
        <div
          className={
            navOpen ? "cross" : lightTheme ? "hamburgerLight" : "hamburger"
          }
        >
        </div>
        <div
          className={
            navOpen ? "cross" : lightTheme ? "hamburgerLight" : "hamburger"
          }
        >
        </div>
      </button>

      <div className={!navOpen ? "overlay" : "overlayShown"}></div>

      <div id="navMenu" style={{ display: !navOpen ? 'none' : '' }} aria-hidden={navOpen && !isLanguagesOpen ? false : true}>
        <div
          id={rightToLeft ? "sliderRTL" : "slider"}
          className={navOpen ? "slide-in" : ""}
        >
          {!rightToLeft && <ul className="navigation2"></ul>}
          <ul className="navigation menuNav">
            {displayYourWatch && (
              <li className="borderBottom"
                aria-setsize="4"
                aria-posinset="1">
                <img alt="" src={YourWatch}></img>
                <button
                  aria-current="page"
                  aria-label={t("yourWatch")}
                  tabindex="-1">
                  {t("yourWatch")}
                </button>
              </li>
            )}
            <li aria-setsize="4"
              aria-posinset="2">
              <a
                title={t("accessibility.serviceCentreTitle")}
                role="button"
                href={`https://www.tudorwatch.com/${selectedLanguage}/retailers?filters=SERVICECENTER&cmpid=tudorwatchid_tudorwatch.com`}
                rel="noreferrer"
                target="_blank"
                onClick={(e) =>
                  handleClick("https://www.tudorwatch.com/retailers")
                }
              >
                <img alt="" src={ServiceCenter}></img> {t("serviceCenter")}
              </a>
            </li>
            <li aria-setsize="4"
              aria-posinset="3">
              <a
                title={t("accessibility.tudorWatchTitle")}
                role="button"
                href={`https://www.tudorwatch.com/${selectedLanguage}?cmpid=tudorwatchid_tudorwatch.com`}
                rel="noreferrer"
                target="_blank"
                onClick={(e) => handleClick("https://www.tudorwatch.com/")}
              >
                <img alt="" src={TudorWatch}></img> Tudorwatch
              </a>
            </li>
            <li onClick={() => setLanguagesOpen(!isLanguagesOpen)} aria-setsize="4"
              aria-posinset="4">
              <button type="button"
                aria-expanded={isLanguagesOpen ? true : false}
                aria-controls="langNav"
                aria-label={t("languages")}
              >
                <img className="languages" alt="" src={Globe}></img> {t("languages")}
              </button>
            </li>
          </ul>
        </div>
      </div>
      <LanguageSwitcher
        error={error}
        rightToLeft={rightToLeft}
        setLanguagesOpen={setLanguagesOpen}
        isLanguagesOpen={isLanguagesOpen}
      />
    </>
  );
};

export default withTranslation()(Navigation);

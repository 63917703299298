import React from "react";
import { withTranslation, useTranslation } from "react-i18next";
import Accordion from "../accordion/accordion";
import Service from "../../assets/icons/service.svg";
import { getServiceString } from "./utils";
import { isUnderWarranty } from "../../utils/helpers/functions.helper";

import "./services.scss";

const Services = ({ rightToLeft, services, warrantyEndDate, lightTheme }) => {
  function counterService(services, stringToCompare) {
    //count how many services have "N" or "Y"
    let counter = 0;
    if (services[0]?.WarrantyExclBra === stringToCompare) counter += 1;
    if (services[0]?.WarrantyExclPrf === stringToCompare) counter += 1;
    if (services[0]?.WarrantyExclWpr === stringToCompare) counter += 1;

    return counter;
  }
  const { t } = useTranslation();

  return (
    <>
      {(services && services[0]?.ServiceType) ||
        counterService(services, "Y") > 0 ? (
        <div className="servicesWrapper">
          <h2 className={lightTheme ? "headerLight" : "header"}>
            {t("tudorCare")}
          </h2>
          <Accordion
            rightToLeft={rightToLeft}
            title={[
              <img src={Service} className="swissFlag" alt="" />,
              t("servicesHeading"),
            ]}
          >
            {services && services[0]?.ServiceType ? (
              <>
                <div className="heading">{t("serviceType")}</div>
                <div className="description">
                  {getServiceString(services[0]?.ServiceType, t)}
                </div>
              </>
            ) : (
              ""
            )}

            {counterService(services, "Y") > 0 ? (
              <>
                <div className="heading">{t("serviceExclusion")}</div>
                <div className="description">
                  {services[0].WarrantyExclBra === "Y" && (
                    <div className="exlcusions">{t("serviceBracelet")}</div>
                  )}
                  {services[0].WarrantyExclPrf === "Y" && (
                    <div className="exlcusions">{t("servicePerformance")}</div>
                  )}
                  {services[0].WarrantyExclWpr === "Y" && (
                    <div className="exlcusions">
                      {t("serviceWaterproofness")}
                    </div>
                  )}
                </div>
              </>
            ) : (
              ""
            )}

            {
              isUnderWarranty(warrantyEndDate) ?
                <p className="warrantyLegalNotice">
                  {t("warrantyLegalNotice")}
                </p>
                : ""
            }

          </Accordion>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default withTranslation()(Services);

const loadDataLayer = (modelName, watchData, status) => {
  let digitalDataLayer = window?.digitalDataLayer || {};
  // LOCATION
  const countryCode = localStorage.i18nextLng;
  const languageCode = localStorage.i18nextLng;

  // PRODUCT
  const rmc = watchData?.Configuration?.Rmc
    ? watchData?.Configuration?.Rmc?.toLowerCase()
    : watchData?.Configuration?.Model?.toLowerCase();
  const catalogLine = watchData?.Brand?.toLowerCase().replace(
    /\s+/g,
    "-"
  );
  // ENVIRONMENT
  const hostName = window.location.hostname;
  const pageURL = watchData
    ? hostName + "/" + catalogLine + "/" + rmc
    : window.location.href;
  const environmentName =
    hostName === "watchid.tudorwatch.com" ? "production" : "staging";
  const environmentVersion = "1.5";
  const statusCode = status;

  // PAGE
  const pageReferrer = document.referrer;
  const modelPageName = watchData ? "watches:" + catalogLine + ":" + rmc : "";
  let pageType = "error page";
  let pageName =
    status === 409
      ? "replay attack error page"
      : status === 400
        ? "authentification error page"
        : "error page";
  let pageSiteSection = "error page";

  // USER
  const userType = watchData?.WarrantyEndDate
    ? "tudor customer"
    : "affiliate/retailer";

  const environment = {
    environmentName,
    environmentVersion,
    statusCode,
  };

  const location = {
    countryCode: countryCode || "",
    languageCode: languageCode || "",
  };

  const page = {
    pageInfo: {
      pageName,
      pageReferrer,
      pageSiteSection,
      pageType,
      pageURL,
    },
  };

  const products = [
    {
      productDetails: {
        productFamily: modelName.toLowerCase(),
        productRMC: rmc,
      },
    },
  ];

  const user = {
    userType,
  };

  digitalDataLayer = {
    environment,
    location,
    page,
  };
  // MODEL
  if (watchData) {
    digitalDataLayer.products = products;
    digitalDataLayer.page.pageInfo.pageType = "model page";
    digitalDataLayer.page.pageInfo.pageName = modelPageName;
    digitalDataLayer.page.pageInfo.pageSiteSection = "watches";
    digitalDataLayer.page.pageInfo.pageSiteSubSection = modelName.toLowerCase();
    digitalDataLayer.user = user;
  } else if (!watchData && status === 200) {
    digitalDataLayer.page.pageInfo.pageType = "pairing mode page";
    digitalDataLayer.page.pageInfo.pageName = "watches:pairing-mode";
    digitalDataLayer.page.pageInfo.pageSiteSection = "watches";
    digitalDataLayer.user = user;
  }
  window.digitalDataLayer = digitalDataLayer;
};

export default loadDataLayer;

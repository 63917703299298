import { supportedLocales, plutonRmc } from "./constants.helper";
import moment from "moment";

/**
 * @function - Returns the most closetest language to i18nextLng.
 * @param {function} i18n - to change language.
 */

export const setLanguageCode = (i18n) => {

  const currentLanguageCode = (window.navigator.language).toLowerCase();

  if (currentLanguageCode) {
    switch (currentLanguageCode.toLowerCase()) {
      case 'zh-tw':
        localStorage.setItem("i18nextLng", "zh-Hant");
        i18n.changeLanguage("zh-Hant");
        break;
      case 'zh-cn':
        localStorage.setItem("i18nextLng", "zh-Hans");
        i18n.changeLanguage("zh-Hans");
        break;
      default:
        const shortenedLocale = supportedLocales.indexOf(currentLanguageCode) >= 0
          ? currentLanguageCode
          : currentLanguageCode?.substring(0, 2);

        i18n.changeLanguage(supportedLocales.indexOf(shortenedLocale) >= 0
          ? shortenedLocale
          : "en");
        break;
    }
  }

}


/**
 * @function - Returns the warranty flag.
 * @param {function} warrantyEndDate.
 * @returns {boolean} 
 */

export const isUnderWarranty = (warrantyEndDate) => {
  const today = new Date();
  const warrantyEndDateFormated = new Date(warrantyEndDate);

  return moment(warrantyEndDateFormated).isSameOrAfter(today, "day");
}


/**
 * @function - Returns is Right-To-Left flag.
 * @returns {boolean}
 */


export const isRightToLeft = () => {

  const selectedLanguageCode = localStorage.getItem("i18nextLng");

  const supportedLocalesDirRtl = supportedLocales.indexOf(selectedLanguageCode) >= 0
    ? selectedLanguageCode
    : selectedLanguageCode?.substring(0, 2);


  return supportedLocalesDirRtl.match(/^(ar|he)$/) && true;

}

/**
 * @function - Returns the given rmc is exist or not flag.
 * @param {String} RMC.
 * @returns {boolean}
 */

export const isPlutonRmc = (rmc) => {
  return plutonRmc.includes(rmc);
}

/**
 * @function - Returns the given businessData's Decor_key is equal to "1" or not flag.
 * @param {Array} businessData array.
 * @returns {boolean}
 */

export const isSpecialEdition = (businessData) => {

  try {
    return businessData.some(item =>
      item.Specspecification.some(specItem =>
        specItem.SpecsComponent.some(specNewItem =>
          specNewItem.Decor_key === "11"
        )
      )
    );
  } catch (e) {
    console.log("exception isSpecialEdition", e)
    return false;
  }

}